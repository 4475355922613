import { createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { addItem, addItems, removeItem, reset } from '../actions/cart.actions';

export interface State {
  ids: number[];
}

export const initialState: State = { ids: [] };

const _counterReducer = createReducer(
  initialState,
  on(addItem, (state, { id }) => ({ ids: _.uniq([...state.ids, id]) })),
  on(removeItem, (state, { id }) => ({ ids: state.ids.filter((existingId) => existingId !== id) })),
  on(addItems, (state, { ids }) => ({ ids: _.uniq([...state.ids, ...ids]) })),
  on(reset, (state) => ({ ids: [] })),
);

export function counterReducer(state, action) {
  return _counterReducer(state, action);
}
