import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faBarcode, faInfoCircle, faPaperclip, faSave, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, first, map, startWith, switchMap } from 'rxjs/operators';
import { ComponentType, ItemStatus, itemStatuses } from 'src/app/enums';
import { ItemChangeSummary, ItemHistoryFactory } from 'src/app/factories/item-history.factory';
import {
  Building,
  Custodian,
  Item,
  ItemAttachment,
  ItemExtended,
  Project,
  PurchaseOrder,
  Room,
  ShippingOrder,
  Site,
  SubCustodyAssignment,
} from 'src/app/models';
import { BuildingsService } from 'src/app/services/buildings.service';
import { ItemsService } from 'src/app/services/items.service';
import { PartsService } from 'src/app/services/parts.service';
import { RoomsService } from 'src/app/services/rooms.service';
import { convertToUtcDates, defaultDate } from 'src/app/services/utility.service';

@Component({
  selector: 'app-item-dialog',
  templateUrl: './item-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      app-item-dialog .mat-tab-body-wrapper {
        margin-top: 12px;
      }
      app-item-dialog mat-tab-body .mat-tab-body-content {
        overflow: hidden;
      }
      app-item-dialog .mat-tab-labels .mat-tab-label {
        height: 42px;
      }
      app-item-dialog .modal-dialog-content {
        padding-top: 0;
      }
      app-item-dialog .history-card {
        margin-bottom: 12px;
      }
      app-item-dialog .mat-tab-label {
        font-size: 1rem;
      }
    `,
  ],
})
export class ItemDialogComponent implements OnInit {
  public attachments$ = new BehaviorSubject<ItemAttachment[]>([]);
  public buildings$: Observable<Building[]>;
  public componentTypes: string[];
  public faBarcode = faBarcode;
  public faInfoCircle = faInfoCircle;
  public faPaperclip = faPaperclip;
  public faSave = faSave;
  public faTimes = faTimes;
  private _filteredNhas: ItemExtended[];
  public filteredNhas: ItemExtended[];
  public form: FormGroup;
  public itemStatuses: ItemStatus[];
  public partOptions$: Observable<{ text: string; value: number }[]>;
  public rooms$: Observable<Room[]>;
  public selectedPartDescription$: Observable<string>;
  public selectedPartNumber$: Observable<string>;
  public versionHistory$ = new BehaviorSubject<ItemChangeSummary[]>([]);
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  public get isEditForm() {
    return !!this.data.item?.id;
  }
  public get displayCheckInButton() {
    return this.data.currentUserCanEdit && (this.data.item.assignedToId || this.data.item.subCustodyAssignmentId);
  }
  constructor(
    public dialogRef: MatDialogRef<ItemDialogComponent>,
    private fb: FormBuilder,
    private itemsService: ItemsService,
    private partsService: PartsService,
    private buildingsService: BuildingsService,
    private roomsService: RoomsService,
    private itemHistoryFactory: ItemHistoryFactory,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      childComponents: ItemExtended[];
      conditionCodeOptions: string[];
      currentUserCanEdit: boolean;
      custodians: Custodian[];
      item: Item;
      items: ItemExtended[];
      nextSequentialSmxIds: string[];
      projects: Project[];
      purchaseOrders: PurchaseOrder[];
      shippingOrders: ShippingOrder[];
      sites: Site[];
      subCustodyAssignments: SubCustodyAssignment[];
      title: string;
      classificationOptions: string[];
    },
  ) {}

  ngOnInit() {
    this.itemStatuses = itemStatuses;
    this.componentTypes = this.itemsService.componentTypes;
    this.partOptions$ = this.partsService.entities$.pipe(
      map((parts) =>
        parts.map((part) => ({
          value: part.id,
          text:
            part.manufacturer && part.manufacturer.name
              ? `${part.manufacturer.name} ${part.partNumber}`
              : part.partNumber,
        })),
      ),
    );

    if (this.data.item.id) {
      this.itemsService
        .getHistoryByItemId(this.data.item.id)
        .pipe(
          first(),
          switchMap((history) => this.itemHistoryFactory.getChanges(history)),
        )
        .subscribe((versionHistory) => this.versionHistory$.next(versionHistory));
    }

    // Don't display items in NHA dropdown if they don't have a SMX ID
    this._filteredNhas = this.data.items.filter((item) => !!item.smxId && !item.nhaId);

    this.filteredNhas = this._filteredNhas;

    const formFields: { [key: string]: any } = {
      assignedToId: [this.data.item.assignedToId],
      buildingId: [this.data.item.buildingId],
      cci: [this.data.item.cci],
      classification: [this.data.item.classification],
      comments: [this.data.item.comments],
      componentType: [this.data.item.componentType],
      conditionCode: [this.data.item.conditionCode],
      hsCode: [this.data.item.hsCode],
      itarEccn: [this.data.item.itarEccn],
      location: [this.data.item.location],
      modelNumber: [this.data.item.modelNumber],
      nhaId: [this.data.item.nhaId],
      orderQuantity: [this.data.item.orderQuantity, Validators.required],
      partId: [this.data.item.partId, Validators.required],
      projectId: [this.data.item.projectId, Validators.required],
      purchaseOrderId: [this.data.item.purchaseOrderId, Validators.required],
      roomId: [this.data.item.roomId],
      serialNumber: [this.data.item.serialNumber],
      shippingOrderId: [this.data.item.shippingOrderId],
      siteId: [this.data.item.siteId, Validators.required],
      smxId: [this.data.item.smxId],
      status: [this.data.item.status, Validators.required],
      subCustodyAssignmentId: [this.data.item.subCustodyAssignmentId],
      totalCost: [this.data.item.totalCost, Validators.required],
      unitPrice: [this.data.item.unitPrice, Validators.required],
      warrantyExp: [defaultDate(this.data.item.warrantyExp)],
    };
    if (this.data.item.id) {
      formFields.id = [this.data.item.id];
      formFields.createdBy = [this.data.item.createdBy];
      formFields.createdTime = [this.data.item.createdTime];
    }
    this.form = this.fb.group(formFields);

    combineLatest([
      this.form.get('orderQuantity').valueChanges.pipe(startWith(this.form.get('orderQuantity').value)),
      this.form.get('unitPrice').valueChanges.pipe(startWith(this.form.get('unitPrice').value)),
    ])
      .pipe(
        map(([orderQuantity, unitPrice]) => {
          // Currency mask forces unitPrice to be string so coerce to number
          if (_.isNumber(orderQuantity) && _.isNumber(unitPrice)) {
            this.form.get('totalCost').setValue(_.round(orderQuantity * unitPrice, 2));
          }
        }),
      )
      .subscribe();

    this.selectedPartDescription$ = combineLatest([
      this.form.get('partId').valueChanges.pipe(startWith(this.form.get('partId').value)),
      this.partsService.entityMap$,
    ]).pipe(
      map(([partId, partDictionary]) => partDictionary[partId]),
      filter((part) => !!part),
      map((part) => part.description),
    );

    this.selectedPartNumber$ = combineLatest([
      this.form.get('partId').valueChanges.pipe(startWith(this.form.get('partId').value)),
      this.partsService.entityMap$,
    ]).pipe(
      map(([partId, partDictionary]) => partDictionary[partId]),
      filter((part) => !!part),
      map((part) => part.partNumber),
    );

    const siteId$ = this.form.get('siteId').valueChanges.pipe(startWith(this.data.item.siteId));

    this.buildings$ = combineLatest([siteId$, this.buildingsService.entities$]).pipe(
      map(([siteId, buildings]) => {
        if (siteId) {
          return buildings.filter((building) => building.siteId === siteId);
        } else {
          return [];
        }
      }),
    );

    const buildingId$ = this.form.get('buildingId').valueChanges.pipe(startWith(this.data.item.buildingId));

    this.rooms$ = combineLatest([buildingId$, this.roomsService.entities$]).pipe(
      map(([buildingId, rooms]) => {
        if (buildingId) {
          return rooms.filter((room) => room.buildingId === buildingId);
        } else {
          return [];
        }
      }),
    );

    this.updateAttachments();
  }

  cancel() {
    this.dialogRef.close(null);
  }

  checkInItem() {
    this.form.patchValue({ assignedToId: null, subCustodyAssignmentId: null, status: ItemStatus.InStock });
    this.save();
  }

  deleteAttachment(attachmentId: number) {
    this.itemsService.deleteAttachment(attachmentId).subscribe((deleted) => {
      if (deleted) {
        this.updateAttachments();
      }
    });
  }

  handleFilterChange(searchTerm: string): void {
    const normalizedQuery = searchTerm.toLowerCase();

    const filterExpression = (item: ItemExtended) =>
      item.smxId.toLowerCase().includes(normalizedQuery) ||
      item.partNumber?.toLowerCase().includes(normalizedQuery) ||
      item.manufacturerName?.toLowerCase().includes(normalizedQuery) ||
      item.partDescription?.toLowerCase().includes(normalizedQuery);

    this.filteredNhas = this._filteredNhas.filter(filterExpression);
  }

  openNewPartModal() {
    this.partsService.openNewDialog().subscribe((part) => {
      if (part) {
        this.form.get('partId').setValue(part.id);
      }
    });
  }

  openNewBuildingModal() {
    this.buildingsService.openNewDialog(this.form.get('siteId').value || null).subscribe((building) => {
      if (building) {
        this.form.get('buildingId').setValue(building.id);
      }
    });
  }

  openNewRoomsModal() {
    this.roomsService.openNewDialog(this.form.get('buildingId').value || null).subscribe((room) => {
      if (room && room.id) {
        this.form.get('roomId').setValue(room.id);
      }
    });
  }

  openNhaDialog(itemId: number) {
    this.itemsService.openEditDialog(itemId).subscribe();
  }

  openUploadAddAttachmentDialog() {
    this.itemsService.openUploadAttachmentDialog(this.data.item).subscribe((uploaded) => {
      if (uploaded) {
        this.updateAttachments();
      }
    });
  }

  save() {
    this.dialogRef.close(convertToUtcDates(this.form.value, ['warrantyExp']));
  }

  updateAttachments() {
    if (this.isEditForm) {
      this.itemsService
        .getAttachmentsByItemId(this.data.item.id)
        .subscribe((attachments) => this.attachments$.next(attachments));
    }
  }
}
