<form [formGroup]="form" novalidate autocomplete="off">
  <div class="modal-dialog-header">
    <button
      type="button"
      tabindex="-1"
      class="close float-right"
      data-dismiss="modal"
      aria-label="Close"
      (click)="cancel()"
      title="Close form and discard any changes."
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <h3 mat-dialog-title>{{ data.title }}</h3>
  </div>
  <mat-dialog-content class="modal-dialog-content">
    <div class="form-group">
      <label for="name">Part Number</label> <input formControlName="partNumber" type="text" class="form-control" />
      <p *ngIf="form.get('partNumber').invalid && form.get('partNumber').dirty" class="text-danger">
        A Part Number is required
      </p>
    </div>

    <div class="form-group">
      <label for="manufacturerId">Manufacturer</label>
      <a (click)="openNewManufacturersModal()" style="margin-top: 2px;" class="float-right small pointer text-primary"
        ><i class="far fa-plus"></i> New Manufacturer</a
      >
      <kendo-combobox
        [data]="manufacturers$ | async"
        [size]="'small'"
        formControlName="manufacturerId"
        [kendoDropDownFilter]="filterSettings"
        [textField]="'name'"
        [valueField]="'id'"
        [valuePrimitive]="true"
        [virtual]="{ itemHeight: 28 }"
        [filterable]="true"
      >
      </kendo-combobox>
      <p *ngIf="form.get('manufacturerId').invalid && form.get('manufacturerId').dirty" class="text-danger">
        A Manufacturer for this part is required
      </p>
    </div>

    <div class="form-group">
      <label for="description">Description</label>
      <textarea formControlName="description" type="text" class="form-control"></textarea>
    </div>

    <div class="form-group">
      <label for="nsn">NSN</label>
      <input formControlName="nsn" type="text" class="form-control" />
      <p *ngIf="form.get('nsn').invalid && form.get('nsn').dirty" class="text-danger">
        A NSN for this part is required
      </p>
    </div>
    <div class="form-group">
      <label for="unitOfIssue">Unit of Issuer</label>
      <input formControlName="unitOfIssue" type="text" class="form-control" />
      <p *ngIf="form.get('unitOfIssue').invalid && form.get('unitOfIssue').dirty" class="text-danger">
        A Unit of Issue for this part is required
      </p>
    </div>
  </mat-dialog-content>
  <div class="modal-dialog-footer">
    <button type="button" class="btn btn-default float-left btn-sm" (click)="cancel()">
      <fa-icon [icon]="faTimes"></fa-icon> Cancel
    </button>
    <button type="button" (submitIfValid)="save()" [form]="form" class="btn btn-primary float-right btn-sm">
      <fa-icon [icon]="faSave"></fa-icon> Save
    </button>
  </div>
</form>
