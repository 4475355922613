import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { GridDataResult, PageChangeEvent, GridComponent, ExcelExportEvent } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, first, tap, startWith, distinctUntilChanged } from 'rxjs/operators';
import { Site, ItemExtended } from 'src/app/models';
import { ItemsService } from 'src/app/services/items.service';
import * as cartActions from '../../actions/cart.actions';
import * as fromRoot from '../../reducers';
import { UsersService } from 'src/app/services/users.service';
import { ExcelExportData, Workbook } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ItemStatus } from 'src/app/enums';
import { SitesService } from 'src/app/services/sites.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./items.component.scss'],
})
export class ItemsComponent implements OnInit, OnDestroy {
  public cartIds = [];
  public currentUserCanEdit$: Observable<boolean>;
  public currentUserIsAdmin$: Observable<boolean>;
  public exporting$ = new BehaviorSubject(false);
  public filterValue$: Observable<string>;
  public gridCount: number;
  public gridData$: Observable<GridDataResult>;
  public items$: Observable<ItemExtended[]>;
  public loaded$: Observable<boolean>;
  public loading$: Observable<boolean>;
  public pageSize = 50;
  public rowHeight = 30;
  public skip$ = new BehaviorSubject<number>(0);
  public subscription: Subscription;
  public userSites$: Observable<Site[]>;
  public sort$ = new BehaviorSubject<SortDescriptor[]>([
    {
      field: 'partNumber',
      dir: 'asc',
    },
  ]);
  public form: FormGroup;
  private formSubscription: Subscription;
  constructor(
    public dialog: MatDialog,
    private itemsService: ItemsService,
    private store: Store<fromRoot.State>,
    private userService: UsersService,
    private sitesService: SitesService,
    private fb: FormBuilder,
  ) {
    this.export = this.export.bind(this);
  }

  ngOnInit() {
    this.loaded$ = this.itemsService.loaded$;
    this.loading$ = this.itemsService.loading$;
    this.filterValue$ = this.itemsService.filter$;
    this.currentUserCanEdit$ = this.userService.currentUserCanEdit$;
    this.currentUserIsAdmin$ = this.userService.currentUserIsAdmin$;
    this.userSites$ = this.sitesService.userSites$;

    this.form = this.fb.group({
      displayActiveItems: [true],
      siteFilter: [null],
    });

    const displayActiveItemsChanges$ = this.form
      .get('displayActiveItems')
      .valueChanges.pipe(startWith(true), distinctUntilChanged());

    const siteFilterChanged$: Observable<number> = this.form.get('siteFilter').valueChanges.pipe(
      startWith(null),
      distinctUntilChanged(),
      tap(() => {
        // Reset page to top
        this.skip$.next(0);
      }),
    );

    this.formSubscription = displayActiveItemsChanges$
      .pipe(
        tap((displayActiveItems) => {
          this.itemsService.getEntities(displayActiveItems);
          // Reset page to top
          this.skip$.next(0);
        }),
      )
      .subscribe();

    this.items$ = combineLatest(
      this.itemsService.filteredEntities$,
      siteFilterChanged$,
      this.sort$,
      displayActiveItemsChanges$,
    ).pipe(
      map(([items, siteFilterChanged, [sort], displayActiveItemsChanges]) => {
        // For some reason we can't trust "displayActiveItemsChanges" when called by the export function
        const activeItems = this.form.get('displayActiveItems').value;
        const siteFilter = this.form.get('siteFilter').value;
        const extendedItems = items.map((item) => ({
          ...item,
          modifiedDate: new Date(item.updatedTime || item.createdTime),
        }));
        const data: ItemExtended[] =
          sort.dir === 'asc' ? _.sortBy(extendedItems, sort.field) : _.sortBy(extendedItems, sort.field).reverse();

        return data
          .filter((item) => (activeItems ? item.status !== ItemStatus.Shipped : item.status === ItemStatus.Shipped))
          .filter((item) => {
            if (siteFilter) {
              return item.siteId === siteFilter;
            } else {
              return true;
            }
          });
      }),
      tap((items) => (this.gridCount = items.length)),
    );

    this.gridData$ = combineLatest(this.items$, this.skip$).pipe(
      map(([items, skip]) => {
        const pagedData = items.slice(skip, skip + this.pageSize);
        return { data: pagedData, total: items.length };
      }),
    );

    this.subscription = this.store.select(fromRoot.selectCartIds).subscribe((cartIds) => (this.cartIds = cartIds));
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
  }

  public addToCart(id: number) {
    // Fetch any items that might be children so we can add them as well
    this.itemsService.getByNhaId(id).subscribe((items) => {
      const childIds = items.map((item) => item.id);
      this.store.dispatch(cartActions.addItems({ ids: [id, ...childIds] }));
    });
  }
  public removeFromCart(id: number) {
    this.store.dispatch(cartActions.removeItem({ id }));
  }
  public addAllToCart() {
    this.items$.pipe(first()).subscribe((items) => {
      const ids = items.map((item) => item.id);
      this.store.dispatch(cartActions.addItems({ ids }));
    });
  }

  public openNewDialog() {
    this.itemsService.openNewDialog().subscribe();
  }

  public openEditDialog(itemId: number) {
    this.itemsService.openEditDialog(itemId).subscribe();
  }

  public delete(item: ItemExtended) {
    this.itemsService.delete(item);
  }
  public sortChange(sort: SortDescriptor[]) {
    this.sort$.next(sort);
  }
  public trackBy(index: number, item: ItemExtended) {
    return item.id;
  }
  public updateFilter(filter: string) {
    this.itemsService.setFilter(filter);
  }
  public pageChange(event: PageChangeEvent) {
    this.skip$.next(event.skip);
  }
  public exportToExcel(grid: GridComponent) {
    this.exporting$
      .pipe(
        first(),
        tap((exporting) => {
          if (!exporting) {
            this.exporting$.next(true);
            grid.saveAsExcel();
          }
        }),
      )
      .subscribe();
  }

  public export(grid: GridComponent): Observable<ExcelExportData> {
    return this.items$.pipe(
      first(),
      map((items) => {
        const result: ExcelExportData = {
          data: process(items, { group: grid.group, sort: grid.sort }).data,
          group: grid.group,
        };
        return result;
      }),
    );
  }

  public onExcelExport(evt: ExcelExportEvent): void {
    evt.preventDefault();
    new Workbook(evt.workbook).toDataURL().then((dataUrl: string) => {
      saveAs(dataUrl, 'Items.xlsx');
      this.exporting$.next(false);
    });
  }
}
