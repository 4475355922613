import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, Provider } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSave, faTimes, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { localStorageSync } from 'ngrx-store-localstorage';
import { NgxBarcodeModule } from 'ngx-barcode';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxPrintModule } from 'ngx-print';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutingModule } from './app.routing.module';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './auth-config';
import { FourOThreeComponent } from './components/403/403.component';
import { AlertComponent } from './components/alert/alert.component';
import { BuildingDialogComponent } from './components/buildings/building-dialog.component';
import { BuildingsComponent } from './components/buildings/buildings.component';
import { AddCartToShippingOrderDialogComponent } from './components/cart/add-cart-to-shipping-order-dialog/add-cart-to-shipping-order-dialog.component';
import { CartComponent } from './components/cart/cart.component';
import { CustodianDialogComponent } from './components/custodians/custodian-dialog.component';
import { CustodiansComponent } from './components/custodians/custodians.component';
import { ItemAttachmentUploadDialogComponent } from './components/items/item-attachment-upload-dialog.component';
import { ItemDialogComponent } from './components/items/item-dialog.component';
import { ItemsComponent } from './components/items/items.component';
import { UpdateItemLocationDialogComponent } from './components/items/update-item-location-dialog.component';
import { ManufacturerDialogComponent } from './components/manufacturers/manufacturer-dialog.component';
import { ManufacturersComponent } from './components/manufacturers/manufacturers.component';
import { CartNavCounterComponent } from './components/nav-menu/cart-nav-counter/cart-nav-counter.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { PartDialogComponent } from './components/parts/part-dialog.component';
import { PartsComponent } from './components/parts/parts.component';
import { ProjectDialogComponent } from './components/projects/project-dialog.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { PurchaseOrderDetailsContainerComponent } from './components/purchase-order-details/purchase-order-details-container.component';
import { PurchaseOrderDetailsComponent } from './components/purchase-order-details/purchase-order-details.component';
import { PurchaseOrderUploadDialogComponent } from './components/purchase-order-upload-dialog/purchase-order-upload-dialog.component';
import { PurchaseOrderDialogComponent } from './components/purchase-orders/purchase-order-dialog.component';
import { PurchaseOrdersComponent } from './components/purchase-orders/purchase-orders.component';
import { ReportDialogComponent } from './components/reports/report-dialog.component';
import { ReportComponent } from './components/reports/report.component';
import { ReportsComponent } from './components/reports/reports.component';
import { RipDialogComponent } from './components/rips/rip-dialog.component';
import { RipsComponent } from './components/rips/rips.component';
import { RoomDialogComponent } from './components/rooms/room-dialog.component';
import { RoomsComponent } from './components/rooms/rooms.component';
import { ConfirmDeleteDialogComponent } from './components/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import { GridFilterInputComponent } from './components/shared/grid-filter-input/grid-filter-input.component';
import { ShippingLocationDialogComponent } from './components/shipping-locations/shipping-location-dialog.component';
import { ShippingLocationsComponent } from './components/shipping-locations/shipping-locations.component';
import { ShippingOrderDetailsContainerComponent } from './components/shipping-order-details/shipping-order-details-container.component';
import { ShippingOrderDetailsComponent } from './components/shipping-order-details/shipping-order-details.component';
import { ShippingOrderUploadDialogComponent } from './components/shipping-order-upload-dialog/shipping-order-upload-dialog.component';
import { ShippingOrderDialogComponent } from './components/shipping-orders/shipping-order-dialog.component';
import { ShippingOrdersComponent } from './components/shipping-orders/shipping-orders.component';
import { SubCustodyAssignmentDetailsContainerComponent } from './components/sub-custody-assignment-details/sub-custody-assignment-details-container.component';
import { SubCustodyAssignmentDetailsComponent } from './components/sub-custody-assignment-details/sub-custody-assignment-details.component';
import { SubCustodyAssignmentDialogComponent } from './components/sub-custody-assignments/sub-custody-assignment-dialog.component';
import { SubCustodyAssignmentsComponent } from './components/sub-custody-assignments/sub-custody-assignments.component';
import { UserNameComponent } from './components/user-name/user-name.component';
import { UserDialogComponent } from './components/users/user-dialog.component';
import { UsersComponent } from './components/users/users.component';
import { VendorDialogComponent } from './components/vendors/vendor-dialog.component';
import { VendorsComponent } from './components/vendors/vendors.component';
import { AutosizeDirective } from './directives/autosize.directive';
import { LabelRequiredDirective } from './directives/label-required.directive';
import { SubmitIfValidDirective } from './directives/submit-if-valid.directive';
import { ApplicationErrorHandler } from './helpers/error-handler';
import { reducers } from './reducers';
import { ApplicationInsightsService } from './services/application-insights.service';
import { EntityStoreModule } from './store/entity-store.module';
import { UpdateProjectForCartItemsDialogComponent } from './components/cart/update-project-for-cart-items-dialog/update-project-for-cart-items-dialog.component';
import { UpdateClassificationDialogComponent } from './components/cart/update-classification-dialog/update-classification-dialog.component';
import { UpdateComponentTypeDialogComponent } from './components/cart/update-component-type-dialog/update-component-type-dialog.component';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['cart'], rehydrate: true })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

let providers: Provider[] = [
  AutosizeDirective,
  LabelRequiredDirective,
  SubmitIfValidDirective,
  NgForm,
  ApplicationInsightsService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true,
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory,
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService,
];

if (environment.production) {
  // Only send errors to app insights for production
  providers = [
    ...providers,
    {
      provide: ErrorHandler,
      useClass: ApplicationErrorHandler,
    },
  ];
}
@NgModule({
  imports: [
    appRoutingModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    EffectsModule.forRoot([]),
    EntityStoreModule,
    ExcelModule,
    FontAwesomeModule,
    FormsModule,
    GridModule,
    GridModule,
    HttpClientModule,
    DropDownsModule,
    SwitchModule,
    LabelModule,
    InputsModule,
    ButtonsModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    MatProgressBarModule,
    MatRadioModule,
    MatTabsModule,
    NgxBarcodeModule,
    NgxCurrencyModule,
    NgxPrintModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    MsalModule,
  ],
  declarations: [
    AddCartToShippingOrderDialogComponent,
    AlertComponent,
    AppComponent,
    AutosizeDirective,
    BuildingDialogComponent,
    BuildingsComponent,
    CustodianDialogComponent,
    CustodiansComponent,
    CartComponent,
    CartNavCounterComponent,
    ConfirmDeleteDialogComponent,
    FourOThreeComponent,
    GridFilterInputComponent,
    ItemAttachmentUploadDialogComponent,
    ItemDialogComponent,
    ItemsComponent,
    LabelRequiredDirective,
    ManufacturerDialogComponent,
    ManufacturersComponent,
    NavMenuComponent,
    PartDialogComponent,
    PartsComponent,
    ProjectDialogComponent,
    ProjectsComponent,
    PurchaseOrderUploadDialogComponent,
    PurchaseOrderDetailsComponent,
    PurchaseOrderDetailsContainerComponent,
    PurchaseOrderDialogComponent,
    PurchaseOrdersComponent,
    ReportComponent,
    ReportDialogComponent,
    ReportsComponent,
    RipDialogComponent,
    RipsComponent,
    RoomDialogComponent,
    RoomsComponent,
    ShippingLocationDialogComponent,
    ShippingLocationsComponent,
    ShippingOrderDetailsComponent,
    ShippingOrderDetailsContainerComponent,
    ShippingOrderDialogComponent,
    ShippingOrdersComponent,
    ShippingOrderUploadDialogComponent,
    SubmitIfValidDirective,
    SubCustodyAssignmentDialogComponent,
    SubCustodyAssignmentsComponent,
    SubCustodyAssignmentDetailsContainerComponent,
    SubCustodyAssignmentDetailsComponent,
    UpdateClassificationDialogComponent,
    UpdateComponentTypeDialogComponent,
    UpdateItemLocationDialogComponent,
    UpdateProjectForCartItemsDialogComponent,
    UserDialogComponent,
    UserNameComponent,
    UsersComponent,
    VendorDialogComponent,
    VendorsComponent,
  ],
  providers: providers,
  bootstrap: [AppComponent, MsalRedirectComponent],
  exports: [AutosizeDirective, LabelRequiredDirective],
})
export class AppModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    library.add(faTimes, faSave, faTrashAlt);
  }
}
