import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'update-component-type-dialog',
  templateUrl: './update-component-type-dialog.component.html',
  styles: [''],
})
export class UpdateComponentTypeDialogComponent implements OnInit {
  public form: FormGroup;
  public faTimes = faTimes;
  public faSave = faSave;
  constructor(
    public dialogRef: MatDialogRef<UpdateComponentTypeDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      componentTypes: string[];
    },
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      componentType: [null, Validators.required],
    });
  }
  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(this.form.value.componentType);
  }
}
