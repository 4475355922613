<form [formGroup]="form" novalidate autocomplete="off">
  <div class="modal-dialog-header">
    <button
      type="button"
      tabindex="-1"
      class="close float-right"
      data-dismiss="modal"
      aria-label="Close"
      (click)="cancel()"
      title="Close form and discard any changes."
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <h3 mat-dialog-title>Update Classification for Cart Items</h3>
  </div>
  <mat-dialog-content class="modal-dialog-content">
    <div class="form-group row">
      <label class="col-sm-5" for="classification">Classification</label>
      <div class="col-sm-7">
        <select formControlName="classification" class="form-control form-control-sm">
          <option [ngValue]="null"></option>
          <option *ngFor="let classification of data.classificationOptions" [ngValue]="classification">{{
            classification
          }}</option>
        </select>
        <p *ngIf="form.get('classification').invalid && form.get('classification').dirty" class="text-danger">
          A Classification is required
        </p>
      </div>
    </div>
  </mat-dialog-content>
  <div class="modal-dialog-footer">
    <button type="button" class="btn btn-default float-left btn-sm" (click)="cancel()">
      <fa-icon [icon]="faTimes"></fa-icon> Cancel
    </button>
    <button type="button" (submitIfValid)="save()" [form]="form" class="btn btn-primary float-right btn-sm">
      <fa-icon [icon]="faSave"></fa-icon> Save
    </button>
  </div>
</form>
