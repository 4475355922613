import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityActionOptions, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import buildQuery from 'odata-query';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { ItemAttachmentUploadDialogComponent } from '../components/items/item-attachment-upload-dialog.component';
import { ItemDialogComponent } from '../components/items/item-dialog.component';
import { UpdateItemLocationDialogComponent } from '../components/items/update-item-location-dialog.component';
import { ConfirmDeleteDialogComponent } from '../components/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import { ComponentType, ItemStatus } from '../enums';
import { UpdateItemLocations } from '../interfaces';
import {
  Custodian,
  Item,
  ItemAttachment,
  ItemExtended,
  Project,
  PurchaseOrderExtended,
  ShippingOrderExtended,
  Site,
  SubCustodyAssignment,
} from '../models';
import { BuildingsService } from './buildings.service';
import { CustodiansService } from './custodians.service';
import { PurchaseOrdersService } from './purchase-orders.service';
import { RoomsService } from './rooms.service';
import { ShippingOrdersService } from './shipping-orders.service';
import { SitesService } from './sites.service';
import { SubCustodyAssignmentsService } from './sub-custody-assignments.service';
import { UsersService } from './users.service';
import { ItemHistory } from '../factories/item-history.factory';
import { ProjectsService } from './projects.service';

const defaultExpansion =
  'part($select=partNumber,description;$expand=manufacturer($select=name)),' +
  'assignedTo($select=firstName,lastName),' +
  'purchaseOrder($select=number,ripNumber;$expand=project($select=code), vendor($select=name))';

@Injectable({ providedIn: 'root' })
export class ItemsService extends EntityCollectionServiceBase<ItemExtended> {
  public classificationOptions = ['CAP', 'GFP'];
  public conditionCodeOptions = ['A', 'B', 'C', 'F'];
  public componentTypes = [
    ComponentType.Component,
    ComponentType.Equipment,
    ComponentType.Material,
    ComponentType.PPE,
    ComponentType.SpecialTestEquipment,
    ComponentType.SpecialTooling,
    ComponentType.SubComponent,
  ];
  private requestAllActiveInflight: Observable<ItemExtended[]> = null;
  private requestAllInactiveInflight: Observable<ItemExtended[]> = null;
  constructor(
    private buildingsService: BuildingsService,
    private custodiansService: CustodiansService,
    private projectsService: ProjectsService,
    private purchaseOrdersService: PurchaseOrdersService,
    private roomsService: RoomsService,
    private shippingOrdersService: ShippingOrdersService,
    private sitesService: SitesService,
    private subCustodyAssignmentsService: SubCustodyAssignmentsService,
    private usersService: UsersService,
    public dialog: MatDialog,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private http: HttpClient,
  ) {
    super('Item', serviceElementsFactory);
  }
  add(entity: ItemExtended, options?: EntityActionOptions) {
    return super.add(this.stripUnnecessaryItemAttributes(entity), options);
  }
  delete(entity: string | number | ItemExtended, options?: EntityActionOptions) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    const delete$ = dialogRef.afterClosed().pipe(
      switchMap((confirmation) => {
        if (confirmation) {
          return super.delete(entity as any, options);
        } else {
          return of(null);
        }
      }),
    );
    delete$.subscribe();
    return delete$;
  }
  deleteAttachment(attachmentId: number) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    return dialogRef.afterClosed().pipe(
      switchMap((confirmation) => {
        if (confirmation) {
          return this.http.delete(`/api/ItemAttachments/${attachmentId}`).pipe(map(() => true));
        } else {
          return of(false);
        }
      }),
    );
  }
  getById(id: number) {
    const query = super.getWithQuery({
      $filter: `id eq ${id}`,
    });
    query.subscribe();
    return query.pipe(map(([item]) => item));
  }
  getByNhaId(nhaId: number) {
    const query = super.getWithQuery({
      $filter: `nhaId eq ${nhaId}`,
    });
    query.subscribe();
    return query;
  }
  getByPurchaseOrderId(purchaseOrderId: number) {
    const query = super.getWithQuery({
      $filter: `purchaseOrderId eq ${purchaseOrderId}`,
    });
    query.subscribe();
    return query;
  }
  getByShippingOrderId(shippingOrderId: number) {
    const query = super.getWithQuery({
      $filter: `shippingOrderId eq ${shippingOrderId}`,
    });
    query.subscribe();
    return query;
  }
  getBySubCustodyAssignmentId(subCustodyAssignmentId: number) {
    const query = super.getWithQuery({
      $filter: `subCustodyAssignmentId eq ${subCustodyAssignmentId}`,
    });
    query.subscribe();
    return query;
  }
  getEntities(active = true) {
    const requestPlaceholder = active ? this.requestAllActiveInflight : this.requestAllInactiveInflight;

    // Break early if there's already a request inflight
    if (requestPlaceholder) {
      return requestPlaceholder;
    }

    const filterQuery = active ? `status ne '${ItemStatus.Shipped}'` : `status eq '${ItemStatus.Shipped}'`;
    const query = super.getWithQuery({
      $filter: filterQuery,
    });
    // No request currently active
    this.setLoading(true);
    if (active) {
      this.requestAllActiveInflight = query;
    } else {
      this.requestAllInactiveInflight = query;
    }

    query.subscribe((items) => {
      if (active) {
        this.setLoaded(true);
      }
      this.setLoading(false);
      if (active) {
        this.requestAllActiveInflight = null;
      } else {
        this.requestAllInactiveInflight = null;
      }
    });

    return query.pipe(map((items) => items));
  }
  getAttachmentsByItemId(itemId: number) {
    const query = buildQuery({ filter: { itemId: itemId } });
    return this.http.get<ItemAttachment[]>(`/api/ItemAttachments${query}`);
  }
  getHistoryByItemId(itemId: number) {
    return this.http.get<ItemHistory[]>(`/api/Items/${itemId}/changes`);
  }
  getNextSequentialSmxId(): Observable<string[]> {
    const queries = [];
    for (let i = 0; i < 10; i++) {
      const query = buildQuery({
        filter: { [`indexof(smxId, 'SMX${i}')`]: { ge: 0 } },
        orderBy: `smxId desc`,
        top: 1,
        select: `smxId`,
      });
      queries.push(query);
    }
    const requests = queries.map((query) => this.http.get(`/api/Items${query}`));
    return combineLatest(requests).pipe(
      map((responses) => {
        const nextHighestIds = [];
        responses.forEach((response, i) => {
          let nextId = `SMX${i}0001`;
          if (response[0]) {
            const currentHighestNumber = response[0]?.smxId?.split(`SMX${i}`).pop();
            const nextHighestNumber = Number(currentHighestNumber) + 1;
            if (nextHighestNumber) {
              nextId = `SMX${i}${nextHighestNumber.toString().padStart(4, '0')}`;
            }
          }
          nextHighestIds.push(nextId);
        });
        return nextHighestIds;
      }),
    );
  }
  update(entity: ItemExtended, options?: EntityActionOptions) {
    const cleanedUpItem = this.stripUnnecessaryItemAttributes(entity);
    return super.update(cleanedUpItem, options);
  }
  public openNewDialog(item: Partial<ItemExtended> = {}): Observable<ItemExtended> {
    const modalDataReady$ = combineLatest([
      this.purchaseOrdersService.loaded$,
      this.buildingsService.loaded$,
      this.roomsService.loaded$,
      this.shippingOrdersService.loaded$,
      this.sitesService.loaded$,
    ]).pipe(
      map(
        ([purchaseOrdersLoaded, buildingsLoaded, roomsLoaded, shippingOrdersLoaded, sitesLoaded]) =>
          purchaseOrdersLoaded && buildingsLoaded && roomsLoaded && shippingOrdersLoaded && sitesLoaded,
      ),
    );

    return combineLatest([
      this.entities$,
      this.purchaseOrdersService.entities$,
      this.shippingOrdersService.entities$,
      modalDataReady$,
      this.usersService.currentUserCanEdit$,
      this.subCustodyAssignmentsService.entities$,
      this.custodiansService.entities$,
      this.sitesService.userSites$,
      this.projectsService.entities$,
      this.getNextSequentialSmxId(),
    ]).pipe(
      filter(
        ([
          items,
          purchaseOrders,
          shippingOrders,
          modalDataReady,
          currentUserCanEdit,
          subCustodyAssignments,
          custodians,
          sites,
          projects,
          nextSequentialSmxIds,
        ]) => !!modalDataReady,
      ),
      first(),
      switchMap(
        ([
          items,
          purchaseOrders,
          shippingOrders,
          modalDataReady,
          currentUserCanEdit,
          subCustodyAssignments,
          custodians,
          sites,
          projects,
          nextSequentialSmxIds,
        ]) => {
          const trimmedItem = this.stripUnnecessaryItemAttributes(item);
          const siteId = item.siteId ? item.siteId : sites[0]?.id;
          const defaultValues = {
            ...trimmedItem,
            // Make sure we set to false if it's not already set
            cci: trimmedItem.cci ? trimmedItem.cci : false,
            // Set default to the next available SMX0XXXX value because most locations use that numbering
            smxId: nextSequentialSmxIds[0],
            siteId: siteId,
            status: ItemStatus.InStock,
          };
          // Make sure we don't include an ID for a new record
          if (defaultValues.id) {
            delete defaultValues.id;
          }

          const dialogRef = this.dialog.open(ItemDialogComponent, {
            minWidth: '60%',
            disableClose: true,
            data: {
              childComponents: [],
              conditionCodeOptions: this.conditionCodeOptions,
              currentUserCanEdit,
              custodians,
              item: defaultValues,
              items,
              nextSequentialSmxIds,
              projects,
              purchaseOrders,
              shippingOrders,
              sites,
              subCustodyAssignments,
              title: 'Create New Item',
              classificationOptions: this.classificationOptions,
            },
          });

          return dialogRef.afterClosed();
        },
      ),
      switchMap((result) => {
        if (result) {
          return this.add(result);
        } else {
          of(result);
        }
      }),
    );
  }
  public openEditDialog(itemId: number): Observable<ItemExtended> {
    const modalDataReady$ = combineLatest([
      this.purchaseOrdersService.loaded$,
      this.buildingsService.loaded$,
      this.roomsService.loaded$,
      this.shippingOrdersService.loaded$,
      this.sitesService.loaded$,
    ]).pipe(
      map(
        ([purchaseOrdersLoaded, buildingsLoaded, roomsLoaded, shippingOrdersLoaded]) =>
          purchaseOrdersLoaded && buildingsLoaded && roomsLoaded && shippingOrdersLoaded,
      ),
    );

    const item$ = this.getById(itemId);

    return combineLatest([
      item$,
      this.entities$,
      this.purchaseOrdersService.entities$,
      this.shippingOrdersService.entities$,
      modalDataReady$,
      this.usersService.currentUserCanEdit$,
      this.subCustodyAssignmentsService.entities$,
      this.custodiansService.entities$,
      this.sitesService.userSites$,
      this.projectsService.entities$,
      this.getByNhaId(itemId),
    ]).pipe(
      filter(
        ([
          item,
          items,
          purchaseOrders,
          shippingOrders,
          modalDataReady,
          currentUserCanEdit,
          subCustodyAssignments,
          custodians,
          sites,
          projects,
          childComponents,
        ]: [
          ItemExtended,
          ItemExtended[],
          PurchaseOrderExtended[],
          ShippingOrderExtended[],
          boolean,
          boolean,
          SubCustodyAssignment[],
          Custodian[],
          Site[],
          Project[],
          ItemExtended[],
        ]) => modalDataReady,
      ),
      first(),
      switchMap(
        ([
          item,
          items,
          purchaseOrders,
          shippingOrders,
          modalDataReady,
          currentUserCanEdit,
          subCustodyAssignments,
          custodians,
          sites,
          projects,
          childComponents,
        ]) => {
          const dialogRef = this.dialog.open(ItemDialogComponent, {
            minWidth: '60%',
            disableClose: true,
            data: {
              childComponents,
              conditionCodeOptions: this.conditionCodeOptions,
              currentUserCanEdit,
              custodians,
              item,
              items,
              projects,
              purchaseOrders,
              shippingOrders,
              sites,
              subCustodyAssignments,
              title: 'Edit Item',
              classificationOptions: this.classificationOptions,
            },
          });

          return dialogRef.afterClosed();
        },
      ),
      switchMap((result) => {
        if (result) {
          return this.update(result);
        } else {
          return of(result);
        }
      }),
    );
  }

  public openUpdateLocationDialog(items: ItemExtended[]) {
    const modalDataReady$ = combineLatest([
      this.buildingsService.loaded$,
      this.roomsService.loaded$,
      this.sitesService.loaded$,
    ]).pipe(map(([buildingsLoaded, roomsLoaded, sitesLoaded]) => buildingsLoaded && roomsLoaded && sitesLoaded));

    return combineLatest([modalDataReady$, this.sitesService.userSites$, this.custodiansService.entities$]).pipe(
      filter(([modalDataReady, sites, custodians]: [boolean, Site[], Custodian[]]) => modalDataReady),
      first(),
      switchMap(([modalDataReady, sites, custodians]) => {
        const dialogRef = this.dialog.open(UpdateItemLocationDialogComponent, {
          minWidth: '60%',
          disableClose: true,
          data: {
            items,
            sites,
            custodians,
          },
        });

        return dialogRef.afterClosed();
      }),
      tap((result: UpdateItemLocations) => {
        if (result) {
          this.updateLocations(result);
        }
      }),
    );
  }

  openUploadAttachmentDialog(item: Item) {
    const dialogRef = this.dialog.open(ItemAttachmentUploadDialogComponent, {
      minWidth: '70%',
      disableClose: true,
      data: { item: item, itemsService: this },
    });

    return dialogRef.afterClosed();
  }

  updateLocations(updateItemLocations: UpdateItemLocations) {
    return this.http.post(`/api/Items/UpdateLocations`, updateItemLocations).pipe(
      map((items: ItemExtended[]) => {
        this.updateManyInCache(items);
        return items;
      }),
    );
  }

  uploadAttachments(itemId: number, files: Set<File>): { [key: string]: { progress: Observable<number> } } {
    // this will be the our resulting map
    const status: { [key: string]: { progress: Observable<number> } } = {};

    files.forEach((file) => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', `/api/ItemAttachments/${itemId}`, formData, {
        reportProgress: true,
      });

      // create a new progress-subject for every file
      const progress = new Subject<number>();

      // send the http-request and subscribe for progress-updates
      this.http.request(req).subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          // calculate the progress percentage

          const percentDone = Math.round((100 * event.loaded) / event.total);
          // pass the percentage into the progress-stream
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.complete();
        }
      });

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        progress: progress.asObservable(),
      };
    });

    // return the map of progress.observables
    return status;
  }

  private stripUnnecessaryItemAttributes(item: ItemExtended | Partial<ItemExtended>) {
    const validFields = [
      'assignedToId',
      'buildingId',
      'cci',
      'classification',
      'comments',
      'componentType',
      'conditionCode',
      'hsCode',
      'itarEccn',
      'location',
      'modelNumber',
      'nhaId',
      'orderQuantity',
      'partId',
      'projectId',
      'purchaseOrderId',
      'roomId',
      'serialNumber',
      'shippingOrderId',
      'siteId',
      'smxId',
      'status',
      'subCustodyAssignmentId',
      'totalCost',
      'unitPrice',
      'warrantyExp',
    ];
    const trimmedItem = {} as ItemExtended;
    validFields.forEach((fieldName) => {
      trimmedItem[fieldName] = item[fieldName] || null;
    });
    const booleans = ['cci'];
    // Set booleans to false if they're not present to prevent issue with returning null
    booleans.forEach((fieldName) => {
      if (!trimmedItem[fieldName]) {
        trimmedItem[fieldName] = false;
      }
    });
    // Optionally add ID if it's an existing item
    if (item.id) {
      trimmedItem.id = item.id;
    }
    return trimmedItem;
  }
}
